const fieldSalesQuestions =[
"Does Jockey offer eco-friendly product lines? ",
"Are Jockey products suitable for sensitive skin? ",
"What is the return policy for Jockey products? ",
"Is there a difference between Jockey's everyday and performance underwear? ",
"How do I care for Jockey modal fabrics? ",
"What kind of support do Jockey sports bras provide? ",
"What innovations has Jockey introduced in their underwear line? ",
"Can you tell me about Jockey's bra fitting guide? ",
"What features make Jockey's shapewear unique? ",
"Can you tell me about Jockey's moisture-wicking technology? ",
"How do I find my correct size for Jockey products? ",
"What are the care instructions for Jockey underwear? ",
"Does Jockey have any products specifically for athletes? ",
"What innovations have been introduced in Jockey's latest collections? ",
"What styles of socks does Jockey offer? ",
"How does Jockey ensure the comfort of their garments? ",
"Are there any seasonal collections available from Jockey? ",
"What benefits do seamless designs offer in Jockey apparel? ",
"What makes Jockey's cotton underwear different from competitors? ",
"Can you recommend Jockey products for everyday wear? ",
"How does Jockey accommodate different body types in their designs? ",
"How has Jockey adapted its product line to meet consumer needs? ",
"How many years has the Jockey brand been in existence? ",
"What is the antimicrobial treatment used in Jockey underwear? ",
"How does Jockey ensure comfort in their activewear? ",
"What care instructions should I follow for Jockey apparel? ",
"Can you explain the difference between Jockey's Classic and Modern fit? ",
"Are there any specific design features in Jockey sleepwear? ",
"How does Jockey accommodate varying body shapes in their designs? ",
"Does Jockey offer eco-friendly product lines? ",
"What feedback do customers give about Jockey's fit and comfort? ",
"Are there seasonal collections for Jockey loungewear? ",
"Can you tell me about the history of the Jockey brand? ",
"How does Jockey make their products size-inclusive? ",
"Do Jockey products have any unique features for athletes? ",
]
module.exports = fieldSalesQuestions;