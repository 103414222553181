import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";

function MessageInput({ handleSend }) {
  const [input, setInput] = useState("");
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend(input);
      setInput("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "sticky",
        bottom: "0",
        padding:"10px",
        backgroundColor:"#212121"
      }}
    >
      <TextField
        placeholder="Type your message here"
        onChange={(e) => setInput(e.target.value)}
        value={input}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  backgroundColor:
                    input.length !== 0 ? "#e3e3e3" : "rgb(137 133 133)",
                  border: "1px",
                }}
                onClick={() => {
                  handleSend(input);
                  setInput("");
                }}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="5 5 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-2xl"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                    fill="#2f2f2f"
                  ></path>
                </svg>
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            "& .MuiOutlinedInput-root": {
              borderRadius: "26px !important",
              backgroundColor: "#2f2f2f",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent !important",
              borderRadius: "26px !important",
            },
            "& .MuiOutlinedInput-input": {
              color: "#ececec",
              fontSize: isMediumDown ?"14px" :"1rem",
              lineHeight:isMediumDown ?"16px" : "1.5rem",
            },
            "& input:-webkit-autofill": {
              borderRadius: "26px !important",
              border: "1px solid transparent !important",
            },
          },
          inputProps: {
            sx: {
              "&::placeholder": {
                color: "#ececec",
                fontSize: isMediumDown ?"14px" :"1rem",
                lineHeight:isMediumDown ?"16px" : "1.5rem",
              },
            },
          },
        }}
        sx={{
          borderRadius: "26px",
          backgroundColor: "#2f2f2f",
          borderColor: "transparent",
          width: isMediumDown ? "95%" : "75%",
        }}
      />
    </Box>
  );
}

export default MessageInput;
