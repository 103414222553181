const retailerQuestions =[
"Is there a difference between Jockey's standard and performance socks?",
"What innovations has Jockey introduced in their sleepwear line?",
"Are Jockey's products tested for durability and wear over time?",
"How does Jockey ensure the quality of their fabric materials?",
"Can you explain the technology behind Jockey's anti-chafing garments?",
"What feedback do customers typically give about Jockey's fit and comfort?",
"How do I choose the right size in Jockey products?",
"Do Jockey products have moisture-wicking properties?",
"How do Jockey's products compare in terms of comfort?",
"What are the benefits of Jockey's seamless designs?",
"Do Jockey products have any special features for athletes?",
"What colors and patterns do Jockey products come in?",
"What support do Jockey bras provide?",
"Is there a difference between Jockey's classic and modern fit?",
"What innovations has Jockey introduced in their latest collection?",
"How many years has Jockey been in business?",
"Can you tell me about the materials used in Jockey activewear?",
"How does Jockey ensure comfort in their products?",
"Can you provide information on Jockey's eco-friendly initiatives?",
"What is unique about Jockey's fit and sizing options?",
"How do Jockey's products compare to other brands?",
"Are there any special care instructions for Jockey garments?",
"Does Jockey offer any products specifically for athletes?",
"Are Jockey products tested for durability?",
"Can you explain the differences between Jockey's classic and modern fits?",
"What features enhance breathability in Jockey's clothing?",
"How does Jockey approach design for athletic support?",
]

module.exports = retailerQuestions;