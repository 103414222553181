const customerQuestions =[
"What materials are Jockey t-shirts made from? ",
"Can I buy Jockey products in bulk or wholesale? ",
"What is the difference between Jockey's classic and modern fit styles? ",
"What are the care instructions for Jockey activewear? ",
"Does Jockey sell shapewear for women? ",
"Are Jockey's materials tested for durability? ",
"What materials are Jockey products made from? ",
"What is the return policy for Jockey items? ",
"How do I know my size in Jockey underwear? ",
"Are Jockey products suitable for sensitive skin? ",
"What is the best way to care for Jockey undergarments? ",
"How does Jockey ensure product comfort and fit? ",
"What is the difference between Jockey's classic and modern fit? ",
"Are Jockey products tailored for specific activities, like sports? ",
"Are there Jockey products specifically for men? ",
"Is there a Jockey loyalty program for regular customers? ",
"How does Jockey address sustainability in its manufacturing? ",
"What year was the Jockey brand established? ",
"What materials are primarily used in Jockey activewear? ",
"Can you tell me about the fit of Jockey bras? ",
"How do I care for Jockey activewear? ",
"Can you describe the benefits of Jockey's CoolMax technology? ",
"Do Jockey briefs come with a moisture-wicking feature? ",
"How can I find my correct size for Jockey products? ",
"Are Jockey products suitable for sensitive skin? ",
"What is the difference between Jockey's classic and modern fit? ",
"Does Jockey have a subscription service for purchasing products? ",
"What innovations does Jockey incorporate in their fabric technology? ",
"How do Jockey's compression garments work? ",
"What is the difference between Jockey shorts and boxer briefs? ",
"Can you provide information on Jockey's history and brand mission? ",
]

module.exports = customerQuestions ;