import { useState } from "react";
import { Container } from "@mui/material";
import MessageContainer from "./container/messageContainer";
import MessageInput from "./input/messageInput";
import Navbar from "./navbar/navbar";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);

  const summarizeMessages = async () => {
    try {
      const summaryApiUrl = process.env.REACT_APP_SUMMARY_API;
      console.log(`Sending request to summarize messages at ${summaryApiUrl}`);

      const summaryResponse = await fetch(summaryApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
        }),
      });

      if (!summaryResponse.ok) {
        const errorText = await summaryResponse.text();
        console.error("Failed to summarize messages:", errorText);
        throw new Error("Failed to summarize messages");
      }

      const summaryData = await summaryResponse.json();
      console.log("Summary data received:", summaryData);
      return summaryData.summary;
    } catch (error) {
      console.error("Error summarizing messages:", error);
      return "";
    }
  };

  const handleSendMessage = async (message) => {
    const senderMessage = {
      sender: "user",
      senderID: "test1",
      senderType: "admin",
      text: message,
    };
    setLoading(true);

    setMessages([...messages, senderMessage]);

    try {
      let summary = "";
      if (messages.length === 0) {
        summary = "";
      } else {
        summary = await summarizeMessages();
      }

      const combinedMessage = `User Query: ${message}`;
      const apiUrl = process.env.REACT_APP_CHAT_API;
      console.log(`Sending chat request to ${apiUrl}`);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: combinedMessage, summary: summary }),
      });
      setLoading(false);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Network response was not ok:", errorText);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Chat API data received:", data);

      const formattedResponse = data.generated_text.startsWith('"')
        ? JSON.parse(data.generated_text)
        : data.generated_text;

      const botMessage = {
        sender: "bot",
        text: formattedResponse,
        productDetails: data.productDetails,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);

      setFeedback(0);

      await saveChatSession(senderMessage, botMessage, feedback);
    } catch (error) {
      console.error("Error handling message:", error);
      setLoading(false);
    }
  };

  const saveChatSession = async (senderMessage, botMessage, feedback) => {
    try {
      const apiUrlSaveSession = process.env.REACT_APP_SAVE_CHAT_API;
      console.log(`Saving chat session to ${apiUrlSaveSession}`);

      const response = await fetch(apiUrlSaveSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error saving chat session:", errorText);
        throw new Error("Error saving chat session");
      }

      const data = await response.json();
      console.log("Chat session saved successfully:", data);
      setSessionId(data.id);
    } catch (error) {
      console.error("Error saving chat session:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error generating response" },
      ]);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        height: "100vh",
      }}
    >
      <Navbar />
      <MessageContainer
        messages={messages}
        feedback={feedback}
        onFeedbackChange={setFeedback}
        handleSend={handleSendMessage}
        loading={loading}
      />
      <MessageInput handleSend={handleSendMessage} />
    </Container>
  );
};

export default Chat;
