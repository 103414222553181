const employeeQuestions =[
" Are Jockey's performance t-shirts made from moisture-wicking materials?",
"Are Jockey's undershirts tagless for added comfort?",
"Can you explain the difference between Jockey's Classic and Modern fit collections?",
"Does Jockey offer any products specifically for athletes?",
"How does Jockey ensure the quality of their fabrics?",
"What is the best way to care for Jockey cotton underwear?",
"Are Jockey's thermal underwear suitable for extreme cold weather?",
"What are the care instructions for Jockey's performance socks?",
"Are Jockey products suitable for sensitive skin?",
"Does Jockey offer moisture-wicking fabrics?",
"What are the care instructions for Jockey products?",
"What is the return policy for Jockey items?",
"What technologies are incorporated in Jockey performance wear?",
"Are there any Jockey products designed for athletic performance?",
"How does Jockey ensure quality in their products?",
"What is the history of the Jockey brand?",
"Does Jockey sell gift cards?",
"What is the material composition of Jockey underwear?",
"How many years has Jockey been in business?",
"How does Jockey ensure comfort in their products?",
"Are Jockey products machine washable?",
"What kind of support do Jockey bras provide?",
"Does Jockey offer any moisture-wicking products?",
"How does Jockey use technology in their product designs?",
"What is Jockey's return policy for online purchases?",
"What are the benefits of the cotton material used in Jockey underwear?",
"How does Jockey accommodate different body types?",
"What innovations have been introduced in Jockey's latest product lines?",
"Are there Jockey products specifically designed for postpartum wear?",
"Can you explain the design process behind Jockey's products?",
"What features make Jockey's products stand out from competitors?",
"What role does functionality play in Jockey's product design?",
"How does Jockey ensure durability in their clothing?",
]
module.exports = employeeQuestions;